import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { injectIntl } from 'react-intl';
import Img from 'gatsby-image/withIEPolyfill';
import Icon from '../../helpers/icon';
import tabsIcons from './tabsIcons';
import styles from './tabs.module.scss';
import PawLink from '../PawLink/PawLink';

const CreateTabsDesktop = ({ blocks }) => {
  return blocks.map((block, index) => (
    <PawLink
      key={block.cta}
      className={styles.tabLink}
      type="unknown"
      destination={block.cta}
      parameters={['']}
    >
      <span
        className={`${styles.tab} ${block.focusTab ? styles.tabActive : null} ${
          block.tabSchedule ? styles.tabSchedule : null
        }`}
        style={{
          color: `${block.textColor ? block.textColor : '#5e5e5e'}`,
          backgroundColor: `${block.bgColor ? block.bgColor : '#fff'}`,
        }}
      >
        {' '}
        {/* {block.localImage && block.focusTab ? (
          <img
            alt={block.altImage}
            className={styles.tabImage}
            src={block.localImage.childImageSharp.fluid.src}
          />
        ) : null} */}
        {block.tabName}
      </span>
    </PawLink>
  ));
};

const CreateTabsMobile = ({ blocks, open, ToogleMobile }) => {
  return blocks.map(block => (
    <>
      {block.focusTab ? (
        <span
          onClick={ToogleMobile}
          className={`${styles.tab} ${styles.tabActive}`}
          style={{
            color: `${block.textColor ? block.textColor : '#5e5e5e'}`,
            backgroundColor: `${block.bgColor ? block.bgColor : '#fff'}`,
          }}
        >
          {' '}
          {/* {block.localImage && block.focusTab ? (
            <img
              alt={block.altImage}
              className={styles.tabImage}
              src={block.localImage.childImageSharp.fluid.src}
            />
          ) : null} */}
          {block.tabName}
          <Icon
            icon="flecha-mobile-gris"
            width="30"
            height="18"
            color={`${block.textColor ? block.textColor : '#5e5e5e'}`}
            className={`${styles.arrow} ${open ? styles.arrowUp : styles.arrowDown}`}
            iconSet={tabsIcons}
          />
        </span>
      ) : null}
    </>
  ));
};

const CreateSubTabsMobile = ({ blocks }) => {
  return blocks.map((block, index) => (
    <PawLink key={block.cta} type="unknown" destination={block.cta} parameters={['']}>
      <span
        className={styles.tab}
        style={{
          color: `${block.textColor ? block.textColor : '#5e5e5e'}`,
          backgroundColor: `${block.bgColor ? block.bgColor : '#fff'}`,
        }}
      >
        {block.tabName}
      </span>
    </PawLink>
  ));
};

export const Tabs = ({ data }) => {
  const [openMobile, setOpenMobile] = useState(false);
  const notFocusList = data.subBlock.filter(unfocus => unfocus.focusTab === false);
  const ToogleMobile = () => {
    setOpenMobile(!openMobile);
  };
  return (
    <>
      <div className="hide-md">
        <div
          className={`${styles.tabContainer} ${
            data.style === 'componente' ? styles.componente : null
          }`}
        >
          <CreateTabsDesktop blocks={data.subBlock} />
        </div>
      </div>
      <div className="hide-md-xl">
        <div
          className={`${styles.tabContainer} ${
            data.style === 'componente' ? styles.componente : null
          }`}
        >
          <CreateTabsMobile blocks={data.subBlock} open={openMobile} ToogleMobile={ToogleMobile} />
          <div className={openMobile ? styles.opened : styles.closed}>
            <CreateSubTabsMobile blocks={notFocusList} />
          </div>
        </div>
      </div>
    </>
  );
};
