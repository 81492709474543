/* eslint-disable no-underscore-dangle */
import { graphql } from 'gatsby';
import React, { useState } from 'react';
import Calendars from '../src/components/Calendar/Calendar2';
import { Freya } from '../src/components/Freya/Freya';
import Heimdall from '../src/components/Heimdall/Heimdall';
import Hod from '../src/components/Hod/Hod';
import Layout from '../src/components/Layout/Layout';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import { Tabs } from '../src/components/TabsAuto/Tabs';
import H4 from '../src/components/Titles/TitleH4single/TitleH4single';
import SEO from '../src/helpers/seo';
import tt from '../src/helpers/translation';
import urlResolver from '../src/helpers/urlResolver';
import './ficha_calendar.scss';

const findDestination = require('../src/helpers/findDestination');
const urls = require('../src/constants/urls');

const Calendar = ({
  data: {
    allCalendarHorario,
    allFichaCalendarRichData,
    allFichaCalendarBreadCrumbBlock,
    allPromosRelacionadasCalendario,
    allCalendarHeaderImage,
    allCalendarSeoData,
    allCalendarTitles,
  },
  pageContext,
}) => {
  const [showBox, setshowBox] = useState(false);

  const toggleBox = () => {
    setshowBox(!showBox);
  };

  const prepareForsetiData = () => {
    const baseTemplate = urls.templates_multilang.ficha_calendar;

    const { urlGatsby } = baseTemplate.urls.filter((a) => a.lang === pageContext.locale)[0];
    const validOptions = { item: baseTemplate.options.item[pageContext.locale] };
    if (pageContext.type === 'park') {
      return {
        style: 'Header',
        subBlock: [
          {
            tabName: 'PORTAVENTURA PARK',
            cta: findDestination.findDestination(urlGatsby, validOptions, ['']),
            focusTab: pageContext.name === 'tag',
            textColor: '#0f0e9f',
            bgColor: '#fff',
          },
          {
            tabName: 'CARIBE AQUATIC PARK',
            cta: findDestination.findDestination(urlGatsby, validOptions, [validOptions.item[2]]),
            focusTab: pageContext.name === 'tagaquatic-park',
            textColor: '#0f0e9f',
            bgColor: '#fff',
          },
          {
            tabName: 'FERRARI LAND',
            cta: findDestination.findDestination(urlGatsby, validOptions, [validOptions.item[1]]),
            focusTab: pageContext.name === 'tagferrari-land',
            textColor: '#0f0e9f',
            bgColor: '#fff',
          },
          {
            tabName: tt('VER HORARIOS DE HOTELES', pageContext.locale),
            // cta: tt('/hoteles/gold-river/horarios-calendario', pageContext.locale),
            cta: findDestination.findDestination(urlGatsby, validOptions, [validOptions.item[3]]),
            focusTab: false,
            textColor: '#fff',
            bgColor: '#fcb42c',
            tabSchedule: true,
          },
        ],
      };
    }

    const listHotels = [
      { name: 'hoteles/gold-river', tabName: 'GOLD RIVER' },
      { name: 'hoteles/port-aventura', tabName: 'PORTAVENTURA' },
      { name: 'hoteles/mansion-de-lucy', tabName: 'MANSION DE LUCY' },
      { name: 'hoteles/caribe', tabName: 'CARIBE' },
      { name: 'hoteles/el-paso', tabName: 'EL PASO' },
      { name: 'hoteles/colorado-creek', tabName: 'COLORADO CREEK' },
      { name: 'hoteles/estacionamiento-autocaravanas', tabName: 'PARKING CARAVANING' },
    ];
    const hotelTabs = listHotels
      .map((item, i) => ({
        tabName: item.tabName,
        cta: findDestination.findDestination(urlGatsby, validOptions, [validOptions.item[i + 3]]),
        // link: `${item.name}/horarios-calendario`,
        focusTab: pageContext.name === `tag${item.name}`,
        textColor: '#3273dc',
        bgColor: '#fff',
      }))
      .filter((_item) => _item !== undefined);

    hotelTabs.unshift({
      tabName: tt('VER HORARIOS DE PARQUES', pageContext.locale),
      cta: findDestination.findDestination(urlGatsby, validOptions, ['']),
      // link: '/horarios-calendario', // p.url destination="ficha_calendar" parameters={['']}
      focusTab: false,
      textColor: '#3273dc',
      bgColor: '#fcb42c',
      tabSchedule: true,
    });

    return {
      style: 'Header',
      subBlock: hotelTabs,
    };
  };

  const prepareCalendarData = () => {
    const out = allCalendarHorario.edges[0].node.calendarTiming.map((item) => ({
      dateFrom: Date.parse(item.date_from),
      dateUntil: Date.parse(item.date_until),
      dateLegend: item.date_legend,
      calendarId: item.id,
    }));

    return out;
  };

  const heimdallData = {
    name: allCalendarTitles.edges[0].node.title,
    image: {
      url: allCalendarHeaderImage.edges[0].node.localImage.childImageSharp.fluid,
    },
  };

  const TitleData = {
    title: allCalendarTitles.edges[0].node.title,
    colorTitle: allCalendarTitles.edges[0].node.colorTitle,
    text: allCalendarTitles.edges[0].node.subTitle,
    description: allCalendarTitles.edges[0].node.description,
    link: allCalendarTitles.edges[0].node.link,
    logo: allCalendarTitles.edges[0].node.logo,
  };

  const prepareFreyaData = () => ({
    title: tt('PROMOCIONES RELACIONADAS', pageContext.locale),
    cards: allPromosRelacionadasCalendario.edges.map((item) => ({
      image: item.node.localImage.childImageSharp.fluid,
      altImage: item.node.image_alt,
      title: item.node.title,
      imgsize: 'promoted',
      buttons: {
        cta: item.node.buttons.cta,
        size: item.node.buttons.size,
        color: item.node.buttons.color,
        ctaText: item.node.buttons.ctaText,
      },
    })),
  });
  return (
    <Layout
      layoutFromOld
      locale={pageContext.locale}
      pageName={pageContext.pageName}
      options={pageContext.options}
    >
      <SEO
        title={allCalendarSeoData.edges[0].node._0.title}
        description={allCalendarSeoData.edges[0].node._1.description}
        pathname={pageContext.url}
        canonicalLink={`https://www.portaventuraworld.com${urlResolver.resolve(
          pageContext.locale,
          'ficha_calendar',
          ['']
        )}`}
        lang={pageContext.locale}
      />
      <div className="is-fullWidth">
        <Heimdall data={heimdallData} />
        <div className="general-index">
          <div className="general-calendar-container">
            <Tabs data={prepareForsetiData()} />

            {/* <BlueArrow /> */}
            <NeoAesir
              locale={pageContext.locale}
              pageName={pageContext.pageName}
              options={pageContext.options}
              title={allFichaCalendarBreadCrumbBlock.edges[0].node.name}
            />
            <Hod
              data={allFichaCalendarRichData.edges[0].node.rich_markup}
              url={pageContext.url}
              img={allCalendarHeaderImage.edges[0].node.localImage.childImageSharp.fluid}
            />
            {pageContext.name !== 'tagaquatic-park' ? (
              <div className="covid-anouncment">
                <h2 className="Title_pagina">{TitleData.title}</h2>
                <p
                  className="subtitle-pagina"
                  dangerouslySetInnerHTML={{ __html: TitleData.text }}
                />

                <div
                  className={`${!showBox ? 'closed' : 'opened'}`}
                  dangerouslySetInnerHTML={{ __html: TitleData.description }}
                />
                {TitleData.description === '' || undefined ? (
                  ''
                ) : (
                  <div className="seemore" onClick={toggleBox} role="presentation">{`${
                    !showBox
                      ? tt('Leer más', pageContext.locale)
                      : tt('Leer menos', pageContext.locale)
                  }`}</div>
                )}
              </div>
            ) : null}

            <Calendars data={prepareCalendarData()} pageContext={pageContext} />

            {/* ACTIVAR PARA NUEVO DISEÑO */}
            {pageContext.type === 'hotel' ||
            pageContext.name === 'tagferrari-land' ||
            pageContext.name === 'tagaquatic-park' ? null : (
              <div className="link-container2">
                <span className="Title_pagina">
                  {tt(
                    'Horario de apertura de las atracciones de PortAventura Park',
                    pageContext.locale
                  )}
                </span>

                {/* <Buttons
                  link={tt(
                    'https://s3-eu-west-1.amazonaws.com/portaventura-world-production-files/wilson_cms/documents/documents/000/015/868/original/horarios_atracciones_diciembre.pdf',
                    pageContext.locale
                  )}
                  blank
                  // noLink
                  size="alone"
                  color="transparente"
                  text={tt('Descargar', pageContext.locale)}
                /> */}
                <h3
                  style={{
                    fontWeight: 'bold',
                    fontSize: '22px',
                    color: '#5e5e5e',
                  }}
                >
                  {tt('PRÓXIMAMENTE', pageContext.locale)}
                </h3>
              </div>
            )}

            {pageContext.name === 'tagferrari-land' ? (
              <div className="link-container2">
                <span className="Title_pagina">
                  {tt('Horario de apertura de las atracciones de Ferrari Land', pageContext.locale)}
                </span>

                {/* <Buttons
                  link={tt(
                    'https://s3-eu-west-1.amazonaws.com/portaventura-world-production-files/wilson_cms/documents/documents/000/015/869/original/horarios_atracciones_diciembre_fl.pdf',
                    pageContext.locale
                  )}
                  blank
                  // noLink
                  size="alone"
                  color="transparente"
                  text={tt('Descargar', pageContext.locale)}
                /> */}
                <h3
                  style={{
                    fontWeight: 'bold',
                    fontSize: '22px',
                    color: '#5e5e5e',
                  }}
                >
                  {tt('PRÓXIMAMENTE', pageContext.locale)}
                </h3>
              </div>
            ) : null}

            <div className="promotion-content">
              <H4 title={prepareFreyaData().title} />
              <Freya data={prepareFreyaData()} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Calendar;

export const GatsbyQuery = () => graphql`
  query fichaCalendarioQuery($name: String!, $locale: String!) {
    allCalendarHorario(filter: { tag: { eq: $name }, lang: { eq: $locale } }) {
      edges {
        node {
          tag
          calendarTiming {
            date_from
            date_until
            date_legend
            updated_at
            created_at
            id
          }
        }
      }
    }
    allCalendarTitles(filter: { tag: { eq: $name }, lang: { eq: $locale } }) {
      edges {
        node {
          title
          subTitle
          colorTitle
          link
          description
          logo
        }
      }
    }
    allCalendarSeoData(filter: { tag: { eq: $name }, lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allCalendarHeaderImage(filter: { tag: { eq: $name }, lang: { eq: $locale } }) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allPromosRelacionadasCalendario(filter: { tag: { eq: $name }, lang: { eq: $locale } }) {
      edges {
        node {
          title
          description
          buttons {
            cta
            size
            color
            ctaText
          }
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
        }
      }
    }
    allFichaCalendarBreadCrumbBlock(filter: { tag: { eq: $name }, lang: { eq: $locale } }) {
      edges {
        node {
          destination_url
          name
        }
      }
    }
    allFichaCalendarRichData(filter: { tag: { eq: $name }, lang: { eq: $locale } }) {
      edges {
        node {
          rich_markup {
            color_css_class
            og_description
            og_title
            twitter_description
            twitter_title
            created_at
            updated_at
            rich_markup_type
          }
        }
      }
    }
  }
`;
