/* eslint-disable no-continue */
/* eslint-disable no-plusplus */
import React, { useState } from 'react';
import Calendars from 'react-calendar/dist/entry.nostyle';
import { injectIntl } from 'react-intl';
import tt from '../../helpers/translation';
import './Calendar2.scss';

const Calendar = ({ data, pageContext, intl: { locale } }) => {
  const [legendMonth, setLegendMonth] = useState();
  const [legendYear, setLegendYear] = useState();

  const finalArr = Object.values(
    data.reduce((previousValue, currentValue) => {
      const { dateFrom, dateUntil, dateLegend } = currentValue;
      const nextValue = { ...previousValue };

      // Create new group
      if (!nextValue[dateLegend]) {
        nextValue[dateLegend] = {
          dateLegend,
          totalDates: [
            {
              dateFrom,
              dateUntil,
            },
          ],
        };
      } else {
        // Append to group
        nextValue[dateLegend].totalDates.push({
          dateFrom,
          dateUntil,
        });
      }

      return nextValue;
    }, {})
  );

  finalArr.sort((a, b) => (a.dateLegend > b.dateLegend ? -1 : 1));

  // const firstAvailableDate = (datesArray, date) => {
  //   let firstDate = date;
  //   let isFirstDateSet = false;

  //   datesArray.forEach(({ totalDates, dateLegend }) => {
  //     totalDates.forEach(({ dateFrom }) => {
  //       if (dateFrom >= date && dateLegend && !isFirstDateSet) {
  //         firstDate = dateFrom;
  //         isFirstDateSet = true;
  //       }
  //     });
  //   });

  //   return new Date(firstDate);
  // };

  const paint = (date) => {
    let defaultClass = '';

    finalArr.forEach(({ totalDates, dateLegend }) =>
      totalDates.forEach(({ dateFrom, dateUntil }) => {
        if (date >= dateFrom && date <= dateUntil) {
          defaultClass = dateLegend;
          if (defaultClass.includes('-')) {
            const noColonDefaultClass = defaultClass.replace(/:/g, '');
            const noSpaceDefaultClass = noColonDefaultClass.replace(/ /g, '');
            const noAsteriskDefaultClass = noSpaceDefaultClass.replace(/\*/g, '');
            const noParentesisDefaultClass = noAsteriskDefaultClass.replace(/ *⁽[^)]*⁾ */g, '');
            defaultClass = `class${noParentesisDefaultClass}`;
          }
        }
      })
    );
    return defaultClass;
  };

  const weAreOpen = (date) => {
    let defaultName = '';
    setLegendMonth(date.getMonth() + 1);
    setLegendYear(date.getYear() + 1900);
    finalArr.forEach(({ totalDates, dateLegend }) =>
      totalDates.forEach(({ dateFrom, dateUntil }) => {
        if (date >= dateFrom && date <= dateUntil) {
          defaultName = dateLegend;
        }
      })
    );
    if (defaultName === 'open') {
      return tt('ABIERTO', locale);
    }
    if (defaultName === 'from 14:00') {
      return tt('desde las 14:00', locale).toUpperCase();
    }
    if (defaultName === 'until 15:00') {
      return tt('hasta 15:00 ', locale).toUpperCase();
    }
    return defaultName;
  };

  // const firstCalendarDate = firstAvailableDate(finalArr, new Date().getTime());

  const content = (
    <Calendars
      calendarType="ISO 8601"
      locale={locale}
      maxDetail="month"
      minDetail="month"
      showNeighboringMonth={false}
      tileContent={({ date }) => weAreOpen(date) || tt('CERRADO', locale)}
      value={new Date()}
      tileClassName={({ date }) => paint(date)}
      navigationLabel={({ label }) => `${label.replace(' de ', ' ')}`}
    />
  );

  return (
    <div>
      {typeof window === 'undefined' ? (
        <div key="calendar-container" className="calendar-container2">
          {content}
        </div>
      ) : (
        <section>
          <div key="calendar-container" className="calendar-container2">
            {content}
          </div>
          {pageContext.name !== 'tagaquatic-park' ? (
            <div className="link-container">
              <span>
                {tt(
                  'Horarios y calendarios sujetos a cambios sin previo aviso.',
                  pageContext.locale
                )}
              </span>
            </div>
          ) : null}

          {pageContext.name === 'tag' &&
          [10, 11].includes(legendMonth) &&
          [2022].includes(legendYear) ? (
            <div className="asterisk-reference">
              <p className="asterisk-text">
                {tt('* Mediterrània y Furius Baco cierran a las 20 h.', pageContext.locale)}
              </p>
              <p className="asterisk-text">
                {tt('** Mediterrània y Far West cierran a las 22 h.', pageContext.locale)}
              </p>
            </div>
          ) : null}
        </section>
      )}
    </div>
  );
};

export default injectIntl(Calendar);
